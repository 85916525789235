.chess-project-page {
    padding: 20px;
    max-width: 1200px;
    margin: 40px auto;
    text-align: center;
    background-color: #000000;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chess-main-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 50px; 
}

.chess-carousel-container {
    flex: 1;
    position: relative;
    max-width: 70%;
}

.chess-project-page img {
    width: 100%;
    height: auto;
    max-height: 450px;
    object-fit: contain;
    border: 1px solid #ffffff;  
}

.chess-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 54px;
    color: white;
    background-color: transparent;
    border: none;
}

.chess-arrow.left {
    left: 10px;
}

.chess-arrow.right {
    right: 5px;
}

.chess-project-description {
    flex: 1;
    text-align: left;
    padding: 40px;
    font-size: 1.2rem;
}

.chess-horizontal-sections {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.chess-horizontal-sections div {
    text-align: center;
}

.chess-horizontal-sections h2 {
    margin-bottom: 10px;
    font-size: 1.5rem; 
}

.chess-horizontal-sections ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 1.1rem; 
    line-height: 1.6; 
}

.chess-horizontal-sections li {
    margin-bottom: 5px; 
}

.chess-project-page a {
    color: rgb(113, 181, 200);
    text-decoration: underline;
}

.chess-project-page a:hover {
    color: #ff6666;
}
