.experience-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh; /* Changed from height: 10vh */
    margin: 0; /* Remove margin, use padding instead */
    text-align: center;
    padding: 80px 20px 20px; /* Account for fixed header */
    width: 100%;
    box-sizing: border-box;
}

h1 {
    color: #ffffff;
    margin-bottom: 30px;
    font-size: clamp(24px, 4vw, 36px); /* Responsive font size */
    width: 100%;
    padding: 0 15px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Responsive grid */
    gap: 30px; /* More consistent than grid-gap */
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
    padding: 0 15px;
    box-sizing: border-box;
}

.grid-item {
    background-color: #605c5c;
    border: 1px solid #403c3c;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    transition: transform 0.3s ease;
    -webkit-transition: transform 0.3s ease; /* iOS compatibility */
    height: 100%;
    min-height: 200px; /* Minimum height for consistency */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.grid-item img {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: contain;
    transition: transform 0.3s ease;
    -webkit-transition: transform 0.3s ease;
    -webkit-backface-visibility: hidden; /* Prevent iOS glitches */
    backface-visibility: hidden;
}

/* Handle hover states */
@media (hover: hover) {
    .grid-item:hover {
        transform: translateY(-5px);
    }

    .grid-item img:hover {
        transform: scale(1.05);
    }
}

/* Touch device active states */
.grid-item:active {
    transform: scale(0.98);
}

.grid-item p {
    margin-top: 15px;
    color: #ffffff;
    font-size: clamp(14px, 2vw, 16px); /* Responsive font size */
    line-height: 1.4;
    padding: 0 10px;
}

/* Media Queries */
@media (max-width: 1024px) {
    .grid-container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        gap: 20px;
    }
}

@media (max-width: 768px) {
    .experience-container {
        padding-top: 60px;
    }

    .grid-container {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 15px;
    }

    .grid-item {
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .experience-container {
        padding-top: 50px;
    }

    h1 {
        margin-bottom: 20px;
    }

    .grid-container {
        grid-template-columns: 1fr; /* Single column on very small screens */
        padding: 0 10px;
    }

    .grid-item {
        min-height: 150px;
    }

    .grid-item img {
        max-height: 150px;
    }
}

/* iOS-specific fixes */
@supports (-webkit-touch-callout: none) {
    .experience-container {
        min-height: -webkit-fill-available;
    }

    .grid-item {
        -webkit-overflow-scrolling: touch;
    }
}

/* Landscape orientation */
@media (orientation: landscape) and (max-height: 500px) {
    .experience-container {
        padding: 70px 20px 20px;
    }

    .grid-container {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .grid-item {
        min-height: 180px;
    }
}