.articles-container {
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  height: -webkit-fill-available; /* iOS height fix */
  margin: 0 auto;
  overflow-x: hidden; /* Prevent horizontal scroll */
  padding-top: 60px; /* Account for fixed header */
}

.articles-container h1 {
  position: absolute;
  top: 90px; /* Adjusted to account for fixed header */
  width: 100%;
  text-align: center;
  color: white;
  font-size: calc(10px + 2vmin);
  transition: top 0.3s ease;
  -webkit-transition: top 0.3s ease; /* iOS compatibility */
  padding: 0 15px; /* Prevent text from touching edges on mobile */
}

.pdf-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px; /* Reduced from 100px for better mobile display */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  padding: 20px;
  width: 100%;
  max-width: 1200px; /* Prevent excessive stretching on large screens */
}

.pdf-viewer {
  position: relative;
  width: min(500px, 90vw); /* Responsive width */
  height: min(640px, 80vh); /* Responsive height */
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  background-color: #fff; /* Add background color */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

iframe {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.fullscreen-button, .go-back-button {
  position: absolute;
  bottom: 10px;
  padding: 12px 20px; /* Larger touch target */
  font-size: 16px;
  color: white;
  background-color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight on iOS */
  z-index: 2; /* Ensure buttons are above content */
}

.fullscreen-button:hover, .go-back-button:hover {
  background-color: #333;
}

.fullscreen-button:active, .go-back-button:active {
  background-color: #444; /* Feedback for touch devices */
}

.fullscreen-button {
  right: 10px;
}

.go-back-button {
  right: 10px;
}

.home-menu {
  width: 100%;
  background-color: #454242;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow: hidden;
  -webkit-backdrop-filter: blur(10px); /* iOS blur effect */
  backdrop-filter: blur(10px);
}

.home-menu ul {
  list-style: none;
  padding: 0;
  margin: 0; /* Changed from 24px */
  height: 100%; /* Changed from 10% */
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px; /* Instead of margin */
}

.home-menu ul li {
  display: inline;
}

.home-menu ul li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 8px 15px;
  display: block;
  line-height: 14px;
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight on iOS */
}

.home-menu ul li a:hover {
  background-color: #645f5f;
}

/* Media Queries */
@media (max-width: 768px) {
  .articles-container h1 {
    font-size: calc(8px + 2vmin);
  }

  .pdf-gallery {
    gap: 30px;
    padding: 10px;
  }

  .pdf-viewer {
    width: 90vw;
    height: 70vh;
  }

  .home-menu ul li a {
    padding: 8px 10px;
  }
}

@media (max-width: 480px) {
  .articles-container {
    padding-top: 40px;
  }

  .articles-container h1 {
    top: 70px;
    font-size: calc(7px + 2vmin);
  }

  .pdf-gallery {
    gap: 20px;
  }

  .pdf-viewer {
    height: 60vh;
  }

  .fullscreen-button, .go-back-button {
    padding: 10px 16px;
    font-size: 14px;
  }

  .home-menu {
    height: 50px;
  }
}

/* iOS-specific fixes */
@supports (-webkit-touch-callout: none) {
  .articles-container {
    min-height: -webkit-fill-available;
  }
}

/* Landscape orientation fixes */
@media (orientation: landscape) and (max-height: 500px) {
  .pdf-viewer {
    height: 80vh;
  }

  .articles-container h1 {
    top: 70px;
  }
}