/* Base styles */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #060606;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.1); /* For iOS compatibility */
    padding: 20px; /* Add padding for smaller screens */
}

.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px; /* Reduced padding for mobile */
    background-color: #454242;
    border-radius: 30px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    -webkit-box-shadow: 0 4px 6px rgba(0,0,0,0.1); /* For iOS compatibility */
    color: #ffffff;
    width: 90%; /* Changed from 100% to allow for margins */
    max-width: 800px;
    margin: 0 auto; /* Center container */
    -webkit-appearance: none; /* Remove default iOS styling */
}

.about-container h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #ffffff;
    font-family: 'Courier New', Courier, monospace;
    font-weight: normal;
    word-wrap: break-word; /* Prevent text overflow */
}

.about-container img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
    -webkit-border-radius: 50%; /* For iOS compatibility */
    object-fit: cover; /* Maintain aspect ratio */
}

.about-container p {
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 20px;
    word-wrap: break-word; /* Prevent text overflow */
}

.social-links {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap; /* Allow icons to wrap on small screens */
    justify-content: center;
    gap: 10px; /* Modern spacing between items */
}

.social-links a {
    color: #61dafb;
    text-decoration: none;
    font-size: 1.5em;
    padding: 5px; /* Larger touch target for mobile */
    -webkit-tap-highlight-color: transparent; /* Remove tap highlight on iOS */
}

.social-links img {
    width: 40px;
    height: 40px;
    vertical-align: middle;
    object-fit: cover; /* Maintain aspect ratio */
}

.social-links a:hover {
    color: #ffffff;
}

/* Media Queries */
@media screen and (max-width: 768px) {
    .about-container {
        padding: 15px;
        width: 95%;
    }

    .about-container h1 {
        font-size: 2em;
    }

    .about-container p {
        font-size: 1em;
    }
}

@media screen and (max-width: 480px) {
    .about-container {
        padding: 10px;
    }

    .about-container h1 {
        font-size: 1.8em;
    }

    .about-container img {
        width: 120px;
        height: 120px;
    }

    .social-links img {
        width: 35px;
        height: 35px;
    }
}

/* iOS-specific fixes */
@supports (-webkit-touch-callout: none) {
    .container {
        min-height: -webkit-fill-available; /* Fix for iOS viewport height issues */
    }
}

/* Fix for notched iPhones */
@supports (padding: max(0px)) {
    .container {
        padding-left: max(20px, env(safe-area-inset-left));
        padding-right: max(20px, env(safe-area-inset-right));
        padding-bottom: max(20px, env(safe-area-inset-bottom));
    }
}