.App {
  text-align: center;
  background-color: #000;
  color: #fff;
  min-height: 100%;
  height: 100vh;
  overflow: hidden;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  font-family: 'Courier New', Courier, monospace;
  margin: 0; /* Added to prevent unwanted spacing */
}

.animated-text {
  font-family: Courier New;
  overflow: hidden;
  border-right: 2px solid rgba(0, 0, 0, 0.75);
  white-space: nowrap;
  animation:
    typing 3s steps(23, end),
    blink-caret 0.75s step-end infinite;
  -webkit-animation: /* Added for iOS compatibility */
    typing 3s steps(23, end),
    blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 14ch;
  }
}

@-webkit-keyframes typing { /* Added for iOS compatibility */
  from {
    width: 0;
  }
  to {
    width: 14ch;
  }
}

@keyframes blink-caret {
  0%,
  100% {
    border-color: transparent;
  }
  50% {
    border-color: rgba(255, 255, 255, 0.75);
  }
}

@-webkit-keyframes blink-caret { /* Added for iOS compatibility */
  0%,
  100% {
    border-color: transparent;
  }
  50% {
    border-color: rgba(255, 255, 255, 0.75);
  }
}

.hidden-header {
  display: none;
}

.HamburgerMenu {
  width: 36px;
  height: 36px;
  position: absolute;
  top: 15px;
  right: 15px;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  text-align: center;
  background-color: #000000;
  -webkit-tap-highlight-color: transparent; /* Added for iOS */
}

.HamburgerMenu div {
  width: 70%;
  height: 3px;
  background-color: rgb(255, 255, 255);  
  margin: 6px auto;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out; /* Added for iOS */
}

.expanded-menu {
  background-color: #cb3232; 
  position: absolute;  
  top: 51px;  
  right: 15px; 
  width: 200px;  
}

.HamburgerMenu.animate div:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
  -webkit-transform: rotate(45deg) translate(5px, 5px); /* Added for iOS */
}

.HamburgerMenu.animate div:nth-child(2) {
  opacity: 0;
}

.HamburgerMenu.animate div:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
  -webkit-transform: rotate(-45deg) translate(7px, -6px); /* Added for iOS */
}

.Navigation {
  display: none;
  position: absolute;
  right: 10px;
  top: 70px;
  text-align: left;
  z-index: 1000;
}

.Navigation.active {
  display: block;
}

.Navigation ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.Navigation li {
  padding: 10px 20px;
  border-bottom: 1px solid #555;
}

.Navigation li:last-child {
  border-bottom: none;
}

.Navigation li a {
  text-decoration: none;
  color: white;
  font-size: calc(8px + 1vmin);
  transition: color 0.3s ease;
  -webkit-transition: color 0.3s ease; /* Added for iOS */
}

.Navigation li a:hover {
  color: #ddd;
}

.Navigation a {
  text-decoration: underline;
  color: white;
  font-family: 'Courier New', Courier, monospace;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: calc(8px + 1vmin);
  transition: color 0.3s ease;
  -webkit-transition: color 0.3s ease; /* Added for iOS */
  background-color: transparent;
  -webkit-tap-highlight-color: transparent; /* Added for iOS */
}

.Navigation a:hover {
  font-weight: bold;
  cursor: pointer;
}

.HomeButton {
  position: absolute;
  top: 12px;
  left: 22px;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  z-index: 2000;
  -webkit-tap-highlight-color: transparent; /* Added for iOS */
}

.HomeButton img {
  width: 34px;
  height: 34px;
}

.HamburgerMenu div.extended {
  width: 120%;
  transition: width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out; /* Added for iOS */
}

.chessboard {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  width: 80vw; 
  height: 80vw; 
  max-width: 400px; 
  max-height: 400px;
  margin: 5vw auto; 
}

.square {
  width: 50px;
  height: 50px;
}

.dark {
  background-color: #b58863;
}

.light {
  background-color: #f0d9b5;
}

.home-menu {
  width: 100%;
  background-color: #454242;
  height: 60px;
  position: fixed; 
  top: 0;
  left: 0;
  z-index: 1000; 
  overflow: hidden; 
}

.home-menu ul {
  list-style: none;
  padding: 0;
  margin: 24px;
  height: 10%;
  display: flex;
  justify-content: flex-end; 
  align-items: center; 
}

.home-menu ul li {
  display: inline;
}

.home-menu ul li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
  padding: 8px 15px; 
  display: block;
  line-height: 14px; 
  -webkit-tap-highlight-color: transparent; /* Added for iOS */
}

.home-menu ul li a:hover {
  background-color: #645f5f;
}

/* Media Queries - keeping the original ones */
@media (max-width: 768px) {
  .App-header {
    padding: 10px;
  }

  .HamburgerMenu {
    top: 10px;
    right: 10px;
  }

  .Navigation {
    top: 45px;
  }

  .Navigation li a,
  .Navigation a {
    font-size: calc(8px + 1vmin);
  }
}

@media (max-width: 480px) {
  .App-header {
    font-size: calc(9px + 2vmin);
  }

  .Navigation {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    top: 30px;
  }

  .Navigation li {
    padding: 15px;
  }

  .HomeButton {
    top: 10px;
    left: 10px;
  }
}