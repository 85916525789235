.game-page {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
  background-color: #ECECEA;
}

.game-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw; 
}

.game {
  max-width: 90%; 
  max-height: 90%; 
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center; 
}


.game-page ol, ul {
  padding-left: 30px;
}

.game-page .board-row:after {
  clear: both;
  content: "";
  display: table;
}

.game-page .status {
  margin-bottom: 10px;
}

.game-page .square {
  background: transparent;
  border: 1px solid transparent;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 48px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 48px;
}

.game-page .square:focus {
  outline: none;
}

.game-page .kbd-navigation .square:focus {
  background: #ddd;
}

.game-page .game-info {
  margin-left: 20px;
}


.icons-attribution, .game-status, .fallen-soldier-block{
  margin-top: 20px;
  min-height: 50px;
}



/*Board color scheme Wheat from http://omgchess.blogspot.com/2015/09/chess-board-color-schemes.html*/
.dark-square{
background-color: RGB(187,190,100);
}

.light-square{
    background-color: RGB(234,240,206);
}

h3{
  margin-bottom: 0px;
}

